@media screen and (max-width: 1440px) {
    .cp-user-sidebar::after {
        bottom: -25%;
    }
    .cp-user-profile-nav {
        justify-content: flex-start;
    }
    .cp-user-profile-nav .nav-link {
        width: 277px;
    }
    .idverifycard{
        padding: 0 !important;
    }
}
@media screen and (max-width: 1366px) {
    .cp-user-profile-nav {
        justify-content: space-between;
    }
    .cp-user-profile-nav .nav-link {
        width: 260px;
    }
    .cp-user-transaction-history-table table thead tr th {
        height: 72px;
    }
    .cp-user-profile-info .profile-edit-btn,
    .input-group-prepend-phone-verify .btn,
    .cp-user-profile-info .form-control {
        height: 45px;
    }
    .cp-user-profile-info .profile-edit-btn {
        font-size: 16px;
    }
    .cp-user-coin-rate ul {
        padding: 15px 30px;
    }
    .cp-user-coin-rate ul li {
        font-size: 18px;
    }
    .cp-user-copy .cp-user-buy-btn {
        height: 55px;
        line-height: 55px;
        font-size: 18px;
    }
    .cp-user-bank-info ul li{
        font-size: 16px;
        height: 85px;
    }
    .cp-user-coin-info .form-control {
        height: 55px;
        font-size: 16px;
    }
    .cp-user-credit-card-area {
        min-height: 805px;
    }
    .cp-user-card-info .form-control {
        height: 55px;
        font-size: 15px;
    }
   .cp-user-copy .input-group {
        height: 55px;
    }
    .cp-user-coin-info-area .cp-user-setupbtn {
        font-size: 18px;
        height: 55px;
        line-height: 55px;
    }
    .cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li {
        padding: 15px 0;
        font-size: 16px;
    }
    .cp-user-profile-info .profile-edit-btn {
        max-width: 635px;
    }
    .cp-user-setting-card .cp-user-content .cp-user-setupbtn {
        font-size: 18px;
        height: 55px;
        line-height: 55px;
    }
    .cp-user-preferance-setting .form-control {
        height: 55px;
        font-size: 18px;
    }
    .cp-user-auth-icon {
        height: 158px;
        width: 158px;
    }
    .cp-user-sidebar-menu ul li a {
        padding: 10px 10px 10px 40px;
    }
    
}
@media screen and (max-width: 1024px) {
    /* .idverifycard{
        padding: 30px !important;
    } */
    .cp-user-top-bar .logo {
        padding-left: 0;
    }

    .cp-user-top-bar-status-area li:not(:last-child) {
        margin-right: 30px;
    }

    .cp-user-deposit-card-select .form-control li {
        background: #2A3865;
    }

    .cp-user-deposit-card-select {
        z-index: 99;
    }

    .verify-input-group {
        margin-left: -8px;
        margin-right: -8px;
    }

    .verify-input {
        padding: 0 8px;
    }
    .id-card-type {
        width: 50%;
        margin-bottom: 30px;
    }
    .cp-user-copy .input-group .btn, .cp-user-copy .input-group input, .cp-user-copy .input-group {
        height: 55px;
    }
}

@media screen and (max-width: 991px) {
    .id-card-type .tagname {
        left: 3px;
    }
    .cp-user-wallet-card .btn {
        width: 150px !important;
    }
    .cp-user-top-bar {
        position: relative;
    }

    .cp-user-top-bar-status-area {
        justify-content: flex-start;
        padding-left: 30px;
    }

    .cp-user-sidebar {
        left: -270px;
        z-index: 99999;
        padding-top: 0;
        box-shadow: 0 10px 20px rgba(24, 35, 70, 0.5);
    }

    .cp-user-sidebar-show {
        left: 0;
    }
    
    .cp-user-sidebar .cp-user-logo {
        min-height: 150px;
        line-height: 150px;
        padding-left: 30px;
    }

    .cp-user-main-wrapper {
        margin-left: 0;
        margin-top: 0;
    }

    .cp-user-sidebar-toggler {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 72%;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 9;
    }

    .cp-user-sidebar-toggler img {
        margin-right: 0;
    }

    .cp-user-profile-info .profile-edit-btn {
        max-width: unset;
    }

    .btn {
        width: 100% !important;
    }

    .cp-user-setting-card .cp-user-content .cp-user-setupbtn {
        min-width: 100%;
    }
    .cp-user-payment-type .form-group label:before {
        padding: 8px;
    }
}

@media screen and (max-width: 768px) {
    .cp-user-top-bar-status-area {
        padding-left: 0;
    }

    .cp-user-top-bar-status-area li:not(:last-child) {
        margin-right: 12px;
    }

    .cp-user-top-bar .cp-user-avater .cp-user-img {
        margin-right: 5px;
        width: 40px;
        height: 40px;
    }

    .cp-user-top-bar .cp-user-avater .cp-user-avater-info {
        line-height: 1;
    }

    .cp-user-top-bar .cp-user-avater .cp-user-avater-info span {
        font-size: 12px;
    }

    .cp-user-top-bar .cp-user-avater .cp-user-avater-info p {
        font-size: 14px;
    }
}

@media screen and (max-width: 425px) {
    .cp-user-top-bar-status-area li .cp-user-content p {
        font-size: 14px;
    }
    .cp-user-sidebar-menu ul li a {
        padding: 10px 10px 10px 40px;
        font-size: 16px;
    }
    .cp-user-sidebar-menu .cp-user-icon img{
        width: 18px;
    }
    .cp-user-sidebar-menu ul li a .cp-user-name {
        margin-left: 15px;
    }
    .cp-user-sidebar {
        width: 250px;
    }
    .id-card-type {
        width: 100%;
        margin: 0 0 30px;
        padding: 0;
    }
    .cp-user-profile-info-id-type{
        margin-top: 0;
    }
    .cp-user-top-bar {
        min-height: 100px;
        line-height: 100px;
    }

    .cp-user-top-bar-right {
        display: table;
        margin-left: auto;
    }

    .cp-user-sidebar-toggler {
        top: 78%;
        transform: translateY(0);
        left: 4%;
    }

    .cp-user-top-bar-status-area li:first-child {
        margin-right: 38px;
    }

    .cp-user-top-bar-status-area li:not(:last-child) {
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .cp-user-coin-rate ul {
        padding: 20px 15px;
    }

    .cp-user-coin-rate ul li {
        font-size: 16px;
    }

    .cp-user-coin-rate .cp-user-img img {
        height: 370px;
        width: 370px;
    }

    .cp-user-main-wrapper {
        padding: 0;
        margin-top: 50px;
    }

    .cp-user-bank-info ul {
        padding: 0;
    }

    .cp-user-bank-info ul li {
        display: block;
        height: unset;
        line-height: initial;
        padding: 10px;
    }

    .cp-user-bank-info ul li span {
        width: 100%;
    }

    .cp-user-wallet-table table tbody tr td ul li img {
        width: 20px;
    }

    .cp-user-wallet-card .cp-user-card-header-area {
        display: block;
    }

    .cp-user-wallet-card .cp-user-card-header-area .cp-user-title {
        margin-bottom: 15px;
    }

    .cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li {
        display: block;
    }

    .cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li span:first-child {
        font-weight: 700;
        font-size: 16px;
    }

    .cp-user-profile-tab-content .cp-user-custom-card {
        padding: 15px 0;
    }

    .user-profile-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 320px;
    }

    .cp-user-profile-nav {
        display: block;
    }

    .cp-user-profile-nav .nav-link {
        width: 100%;
    }

    .input-group-prepend .btn {
        width: 115px;
        padding: 0 10px;
    }

    .input-group-prepend .btn::before {
        right: 10px;
    }

    .cp-user-share-buttons ul {
        display: block;
    }

    .cp-user-share-buttons ul li a {
        width: 100%;
        margin: 15px 0;
    }

    .cp-user-share-buttons ul li:not(:last-child) {
        margin-right: 0;
    }
    .cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li {
        padding: 10px 0;
        font-size: 14px;
    }
    .sidebarclose{
        display: block;
        color: #ffffff;
        font-size: 20px;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .cp-user-payment-type .form-group label:before {
        padding: 8px;
    }
    .cp-user-payment-type .form-group input:checked+label:after {
        top: 9px;
        left: 7px;
        width: 6px;
        height: 10px;
    }
    .cp-user-coin-info-area .cp-user-setupbtn {
        min-width: 100%;
    }
    .cp-user-profile-nav .cp-user-img img {
        width: 25px;
    }
    .cp-user-profile-nav .nav-link {
        font-size: 16px;
    }
    .modal-header .close {
        top: -85px;
        right: 15px;
    }

}

@media screen and (max-width: 375px) {
    .id-card-type .tagname {
        left: -10px;
    }
    .cp-user-wallet-card .btn {
        width: 100% !important;
        margin: 10px;
    }
    .cp-user-top-bar-status-area li:not(:last-child) {
        margin-bottom: 0;
        margin-top: 0px;
    }

    .cp-user-top-bar-status-area li:first-child {
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        left: 0;
    }

    .cp-user-copy .input-group .btn {
        width: 90px;
    }
}

@media screen and (max-width: 320px) {
    .cp-user-top-bar-status-area li:last-child {
        margin-top: 15px;
    }

    .cp-user-sidebar-toggler {
        top: 81%;
        left: 6%;
    }
    .cp-user-coin-rate ul li {
        font-size: 13px;
    }
}