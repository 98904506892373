/*
-------------------------------------------------------------
Theme Name: Cpockate
Theme URI: 
Author: itechtheme
Author URI: 
Description: Cpockate is a modern dashboard template based on html css responsive with bootstrap.We’ve used modern technologies and best practices to make our product easy to work with It’s the most convenient template for developers clean code and detailed documentation, which allows you to build any projects easily! Use it for Sales Monitoring, Event Management, Helpdesk Management, Ad Campaign, analytics and other types of web or mobile applications.
Version: 1.0
License: 
License 
Text Domain: cpockate
Tags: admin, best react admin template, dashboard, material design, bootstrap, responsive, react, react admin, react admin template, react dashboard, react dashboard template, react firebase, react js, React redux, react template, react-redux
-------------------------------------------------------------

-------------------------------------------------------------
#Css index
-------------------------------------------------------------
01). Common css
02). Topbar css
03). Sidebar css
04). main wrapper css
05). Dashboard css
06). Buy Coin css
07). pockaet wallet css
08). withdraw css
09). Activity css
10). Profile css
11). Setting css
12). Referral css
-------------------------------------------------------------
*/


/*
----------------------------------------------------------
    (01) Common css
----------------------------------------------------------
*/
@import url('https://fonts.googleapis.com/css?family=Karla:400,700&display=swap');

*,
ul,
ol {
    margin: 0;
    padding: 0;
}

body {
    font-size: 14px;
    font-family: 'Karla', sans-serif;
    margin: 0;
    padding: 0;
    color: #676B79;
}

ul,
ol {
    list-style: none;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none !important;
}

input:focus,
a:hover,
a:focus,
a:active,
button:focus,
select:focus {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

body h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2A2A2D;
}

p {
    line-height: 30px;
}

.btn,
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0;
}

.cp-user-body-bg {
    background: #182346;
}

.cp-user-content-expend {
    margin-left: 0 !important;
}


.cp-user-header-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
}

.padding-30 {
    padding: 30px;
}

.cp-user-custom-card {
    background: #212E55;
    min-height: 540px;
}


.cp-user-card-header-area {
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cp-user-card-header-area h4 {
    font-weight: 700;
    font-size: 20px;
    color: #fff;
}

.cp-user-chart-dropdown .btn {
    color: #B4B8D7;
    font-size: 16px;
    border: 1px dashed #111A35;
    border-radius: 5px;
    padding: 8px 20px;
}

.cp-user-chart-dropdown .dropdown-menu {
    min-width: 112px;
    min-width: 7rem;
    text-align: center;
    padding: 0;
}

.cp-user-chart-dropdown .dropdown-toggle:after {
    border: none;
    content: url(../images/angle-down.svg);
    vertical-align: middle;
}

.form-control:focus{
    box-shadow: none;
    border-color: #111A35;
}
.form-control::-webkit-input-placeholder {
    color: #B4B8D7 !important;
    font-size: 16px !important;
}

.form-control::-moz-placeholder {
    color: #B4B8D7 !important;
    font-size: 16px !important;
}

.form-control:-ms-input-placeholder {
    color: #B4B8D7 !important;
    font-size: 16px !important;
}

.form-control:-moz-placeholder {
    color: #B4B8D7 !important;
    font-size: 16px !important;
}


/*
----------------------------------------------------------
    (01) Common css
----------------------------------------------------------
*/






/*
----------------------------------------------------------
    (02) Topbar css
----------------------------------------------------------
*/

.cp-user-top-bar {
    background: #212E55;
    min-height: 130px;
    line-height: 130px;
    position: fixed;
    z-index: 3;
    width: 100%;
    top: 0;
    left: 0;
}

.cp-user-top-bar .logo {
    padding-left: 30px;
}

.cp-user-top-bar-status-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.cp-user-top-bar-status-area li:not(:last-child) {
    margin-right: 100px;
}

.cp-user-top-bar-status-area li {
    color: #B4B8D7;
}

.cp-user-top-bar-status-area li .cp-user-title {
    font-size: 14px;
    text-transform: capitalize;
}

.cp-user-top-bar-status-area li .cp-user-content p {
    font-size: 16px;
    line-height: 1.5;
}

.cp-user-top-bar-status-area li.cp-user-pending-withdrawal .cp-user-content .cp-user-btc span,
.cp-user-top-bar-status-area li.cp-user-pending-withdrawal .cp-user-content .cp-user-usd span,
.cp-user-top-bar-status-area li .cp-user-content .cp-user-last-visit span {
    color: #FF5556;
}

.cp-user-top-bar-status-area li.cp-user-available-balance .cp-user-content .cp-user-btc span,
.cp-user-top-bar-status-area li.cp-user-available-balance .cp-user-content .cp-user-usd span,
.cp-user-top-bar-status-area li .cp-user-content .cp-user-today span {
    color: #0DE06D;
}

.cp-user-top-bar .cp-user-avater {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cp-user-top-bar .cp-user-avater .cp-user-avater-info {
    text-align: left;
    color: #B4B8D7;
}

.cp-user-top-bar .cp-user-avater .cp-user-avater-info span {
    font-size: 14px;
}

.cp-user-top-bar .cp-user-avater .cp-user-avater-info p {
    font-weight: 700;
    font-size: 16px;
}

.cp-user-top-bar .cp-user-avater .cp-user-img {
    margin-right: 10px;
    padding: 5px;
    background: #E8E9EF;
    width: 60px;
    height: 60px;
}

.cp-user-top-bar .cp-user-avater .cp-user-img,
.cp-user-top-bar .cp-user-avater .cp-user-img img {
    border-radius: 50%;
}

.cp-user-top-bar-right .dropdown-toggle:after {
    display: none;
}
.cp-user-top-bar-right .dropdown-menu{
    padding: 0;
}
.cp-user-top-bar-right .dropdown-menu .dropdown-item{
    line-height: 2 !important;
}


/*
----------------------------------------------------------
    (02) Topbar css
----------------------------------------------------------
*/




/*
----------------------------------------------------------
    (03) Sidebar css
----------------------------------------------------------
*/

.cp-user-sidebar {
    width: 270px;
    position: fixed;
    height: 100vh;
    background: #212E55;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    left: 0;
    top: 0;
    z-index: 1;
    padding-top: 180px;
}
.cp-user-sidebar::after {
    position: absolute;
    content: url(../images/sidebar-coin-img.svg);
    bottom: -5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.cp-user-sidebar-menu ul li a {
    padding: 15px 10px 15px 40px;
    display: block;
    color: #B4B8D7;
    font-size: 18px;
}

.cp-user-sidebar-menu ul li ul li a {
    padding: 5px 30px 5px 75px;
}

.cp-user-sidebar-menu ul li ul li.sub-active a,
.cp-user-sidebar-menu ul li ul li:hover a {
    color: #F7941E;
}

.cp-user-sidebar-menu ul li:hover>a {
    color: #F7941E;
}

.cp-user-sidebar-menu ul li:hover>a .cp-user-icon .cp-user-side-bar-icon,
.cp-user-sidebar-menu ul li.cp-user-active-page .cp-user-icon .cp-user-side-bar-icon,
.cp-user-side-bar-icon-hover {
    display: none;
}

.cp-user-sidebar-menu ul li.cp-user-active-page a {
    color: #F7941E;
}

.cp-user-sidebar-menu ul li:hover>a .cp-user-icon .cp-user-side-bar-icon-hover,
.cp-user-sidebar-menu ul li.cp-user-active-page .cp-user-icon .cp-user-side-bar-icon-hover {
    display: inline-block;
}

.cp-user-sidebar-menu ul li a .cp-user-name {
    margin-left: 30px;
}

.cp-user-active-page a:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 5px;
    background: #F7941E;
    right: 0;
    top: 0;
    border-radius: 10px;
}

.cp-user-sidebar-menu ul li a {
    position: relative;
}


.cp-user-sidebar-hide {
    left: -270px;
}
.sidebarclose{
    display: none;
}


/*
----------------------------------------------------------
    (03) Sidebar css
----------------------------------------------------------
*/



/*
----------------------------------------------------------
    (04) main wrapper css
----------------------------------------------------------
*/

.cp-user-main-wrapper {
    margin-left: 270px;
    padding: 50px 15px;
    margin-top: 130px;
}

.cp-user-main-wrapper-expend {
    margin-left: 0 !important;
}


/*
----------------------------------------------------------
    (04) main wrapper css
----------------------------------------------------------
*/

/*
----------------------------------------------------------
    (05) Dashboard css
----------------------------------------------------------
*/

ul.cp-user-legend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

ul.cp-user-legend .cp-user-color-box {
    height: 15px;
    width: 20px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 10px;
}

ul.cp-user-legend li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #B4B8D7;
    font-size: 16px;
}

ul.cp-user-legend li:not(:last-child) {
    margin-right: 30px;
}

ul.cp-user-legend .cp-user-color-box-1 {
    background: #5347FF;
}

ul.cp-user-legend .cp-user-color-box-2 {
    background: #F7941E;
}

ul.history-legend .cp-user-color-box-2 {
    background: #0DE06D;
}

.cp-user-transaction-history-table {
    min-height: 400px;
    margin-top: 30px;
}

.cp-user-transaction-history-table table tr th,
.cp-user-transaction-history-table table tr td {
    color: #B4B8D7;
}

.cp-user-transaction-history-table table tr th {
    font-size: 18px;
    font-weight: 700;
}

.cp-user-transaction-history-table table tr td {
    font-size: 16px;
    font-weight: 400;
}

.cp-user-transaction-history-table table tr td,
.cp-user-transaction-history-table table thead tr th {
    height: 74px;
    vertical-align: middle;
    padding: 0 !important;
}

.cp-user-transaction-history-table table thead tr th,
.cp-user-transaction-history-table table tbody tr td {
    border-bottom: 1px solid #182346;
}

.cp-user-transaction-history-table table tbody tr td:first-child:focus {
    box-shadow: none;
    outline: none;
}

.cp-user-transaction-history-table table thead tr th {
    border-top: 1px solid #182346;
}

.cp-user-transaction-history-table table tbody tr {
    background: transparent;
}

table.dataTable.no-footer {
    border: none;
}

.dataTables_info {
    display: none;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    top: 50%;
    left: 4px;
    height: 16px;
    width: 16px;
    border: 2px solid #F7941E;
    border-radius: 16px;
    line-height: 16px;
    background-color: #F7941E;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*
----------------------------------------------------------
    (05) Dashboard css
----------------------------------------------------------
*/



/*
----------------------------------------------------------
    (06) Buy Coin css
----------------------------------------------------------
*/

.cp-user-payment-type .form-group {
    display: block;
    margin-bottom: 30px;
}

.cp-user-payment-type .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.cp-user-payment-type .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 18px;
    color: #B4B8D7;
    margin-bottom: 0;
}

.cp-user-payment-type .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #2B3C70;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 12px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 3px;
}

.cp-user-payment-type .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 12px;
    width: 6px;
    height: 14px;
    border: solid #0DE06D;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cp-user-payment-type .form-group input:checked+label:before {
    border-color: #0DE06D;
}

.cp-user-buy-coin-content-area p {
    color: #B4B8D7;
    border-bottom: 1px dashed #2B3C70;
    padding-bottom: 50px;
    margin-bottom: 50px;
    font-size: 16px;
}

.cp-user-coin-info .form-control {
    height: 70px;
    border-radius: 3px;
    border: 1px solid #2B3C70;
    background: transparent;
    box-shadow: none;
    color: #B4B8D7;
    font-size: 18px;
}

.cp-user-coin-info label {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.cp-user-coin-info .form-control::-webkit-input-placeholder {
    color: #B4B8D7;
    font-size: 18px;
    text-transform: capitalize;
}

.cp-user-coin-info .form-control::-moz-placeholder {
    color: #B4B8D7;
    font-size: 18px;
    text-transform: capitalize;
}

.cp-user-coin-info .form-control:-ms-input-placeholder {
    color: #B4B8D7;
    font-size: 18px;
    text-transform: capitalize;
}

.cp-user-coin-info .form-control:-moz-placeholder {
    color: #B4B8D7;
    font-size: 18px;
    text-transform: capitalize;
}

.cp-user-payment-type {
    margin-top: 50px;
}

.cp-user-payment-type h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.cp-user-coin-rate ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 30px 50px;
    background: #182346;
    border-radius: 5px;
    margin-bottom: 33px;
}

.cp-user-coin-rate ul li {
    color: #B4B8D7;
    font-size: 20px;
    font-weight: 700;
}

.cp-user-coin-rate .img img {
    height: 430px;
    width: 430px;
    display: table;
    margin: auto;
}

/* Bit Coin */

.cp-user-bit-coin-card {
    min-height: 693px;
}

.cp-user-cp-user-coin-info-area h5 {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
}

.cp-user-qr {
    display: table;
    margin: 50px auto;
}

.cp-user-qr img {
    width: 150px;
}

.cp-user-copy .input-group .btn,
.cp-user-copy .input-group input,
.cp-user-copy .input-group {
    height: 70px;
}

.cp-user-copy .input-group {
    border: 1px solid #2B3C70;
    border-radius: 3px;
}

.cp-user-copy .input-group .btn {
    background: #2A3865;
    width: 130px;
    color: #B4B8D7;
    text-transform: capitalize;
}

.cp-user-copy .input-group .form-control {
    background: transparent;
    text-align: center;
    color: #B4B8D7;
}

.cp-user-copy .input-group .form-control,
.cp-user-copy .input-group .form-control:active,
.cp-user-copy .input-group .form-control:focus {
    border: none;
    box-shadow: none;
}

.cp-user-copy .cp-user-buy-btn {
    height: 70px;
    line-height: 70px;
    padding: 0;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 20px;
    background: #2A3865;
    color: #fff;
    border-radius: 3px;
    max-width: 530px;
    width: 100%;
    display: block;
    margin: 30px auto;
}

/* credit card */

.cp-user-credit-card-area {
    min-height: 915px;
}

.cp-user-card-info .form-group {
    margin-bottom: 30px;
}

.cp-user-card-info .form-control {
    height: 70px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #2B3C70;
    color: #B4B8D7;
}

.cp-user-card-info label {
    color: #B4B8D7;
    font-size: 18px;
    text-transform: capitalize;
}

/* Bank info */
#cp-user-upload-bank-info {
    display: none;
}

.cp-user-upload-info {
    margin-top: 50px;
}

.cp-user-upload-info label {
    background: #1B2953;
    max-width: 280px;
    width: 100%;
    height: 120px;
    border-radius: 3px;
    border: 2px dashed #2B3C70;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.cp-user-bank-info ul {
    display: block;
    background: transparent;
    padding-left: 0;
    padding-right: 0;
}

.cp-user-bank-info ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cp-user-bank-info ul li span {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
}

.cp-user-bank-info ul li:first-child {
    border-top: 1px solid #253562;
}

.cp-user-bank-info ul li {
    color: #B4B8D7;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #253562;
    border-left: none;
    border-right: none;
    height: 90px;
}

.cp-user-bank-info ul li .cp-user-dot {
    text-align: center;
}

/*
----------------------------------------------------------
    (06) Buy Coin css
----------------------------------------------------------
*/



/*
----------------------------------------------------------
    (07) pockaet wallet css
----------------------------------------------------------
*/

.cp-user-wallet-table table {
    text-align: center;
}

.cp-user-wallet-table table thead tr th {
    border-bottom: none;
    font-weight: 700;
    font-size: 18px;
}

.cp-user-wallet-table table tbody tr {
    background: transparent !important;
}

.cp-user-wallet-table table tbody tr td {
    font-size: 16px;
    font-weight: 400;
}

.cp-user-wallet-table table tbody tr td ul li:not(:last-child) {
    margin-right: 20px;
}

.cp-user-wallet-table table tbody tr td,
.cp-user-wallet-table table thead tr th {
    vertical-align: middle;
    color: #B4B8D7;
}

.cp-user-wallet-table table thead tr th,
.cp-user-wallet-table table tbody tr td {
    border-bottom: 1px solid #182346;
    height: 70px;
}

.cp-user-wallet-table table thead tr th {
    border-top: 1px solid #182346;
}
.cp-user-wallet-table ul li a img{
    width: 22px;
}

.cp-user-pagin ul li a img{
    width: 14px;
}
.cp-user-wallet-card .cp-user-card-header-area {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    padding: 30px 0;
}

.cp-user-wallet-card .btn {
    width: 150px;
    height: 50px;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
}

.cp-user-wallet-card .btn.cp-user-add-pocket {
    background: #0DE06D;
    margin-right: 10px;
}

.cp-user-wallet-card .btn.cp-user-move-coin {
    background: #FBAE51;
}

.cp-user-pagin {
    border-radius: 50px;
    border: 1px solid #182346;
    max-width: 370px;
    width: 100%;
    margin: auto;
    margin-top: 30px;
}

.cp-user-pagin ul li a {
    padding: 10px;
    display: block;
    font-size: 23px;
    color: #fff;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.cp-user-select-area {
    position: relative;
}

.cp-user-select-area:after {
    position: absolute;
    content: url(../images/cart-down.svg);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.form-control {
    height: 60px;
    border: 1px solid #DCDFF2;
    border-radius: 3px;
    box-shadow: none;
    color: #212E55;
}

.modal .form-control::-webkit-input-placeholder {
    color: #212E55 !important;
    font-size: 16px !important;
}

.modal .form-control::-moz-placeholder {
    color: #212E55 !important;
    font-size: 16px !important;
}

.modal .form-control:-ms-input-placeholder {
    color: #212E55 !important;
    font-size: 16px !important;
}

.modal .form-control:-moz-placeholder {
    color: #212E55 !important;
    font-size: 16px !important;
}

.modal label {
    color: #212E55;
    font-size: 16px;
}

.cp-user-move-btn {
    padding: 0;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    background: #2A3865;
    text-transform: capitalize;
}

.cp-user-move-btn,
.cp-user-move-btn:hover {
    border-radius: 3px;
    color: #fff;
}

.move-coin-modal .modal-content {
    position: relative;
    border-radius: 30px;
}

.move-coin-modal .modal-content h3 {
    font-size: 24px;
    font-weight: 700;
    color: #111A35;
}

.move-coin-modal .modal-content .cp-user-img-vector {
    margin-top: -70px;
}

.move-coin-modal .close {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    text-align: center;
    position: absolute;
    top: -15px;
    right: -15px;
    background: #E9EBF2;
}

.move-coin-modal .close,
.move-coin-modal .close:hover {
    opacity: 1 !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}


.cp-user-deposit-card-select.cp-user-select-area:after {
    position: absolute;
    content: url(../images/cart-down.svg);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    height: 1px;
    width: 20px;
    line-height: 1;
}

.cp-user-deposit-card.cp-user-custom-card {
    min-height: 750px;
}

.cp-user-withdraw-card .cp-user-card-header-area,
.cp-user-deposit-card .cp-user-card-header-area {
    border-bottom: 1px dashed #2B3C70;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 80px;
}

.cp-user-deposit-card .cp-user-qr {
    margin-top: 0;
}

.cp-user-deposit-card .cp-user-qr img {
    width: 180px;
}

.cp-user-deposit-card-select .form-control {
    background: #2A3865;
    border: 1px solid #2A3865;
    border-radius: 3px;
    box-shadow: none;
    color: #B4B8D7;
    text-transform: capitalize;
    line-height: 60px;
    padding: 0;
}

.cp-user-deposit-card-select .form-control .init {
    font-size: 16px;
    font-weight: 700;
    padding-right: 40px;
}

.cp-user-deposit-card-select .cp-user-title {
    font-weight: 700;
    font-size: 18px;
}

.cp-user-deposit-card-select ul li {
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #2A3865;
    height: 60px;
    line-height: 60px;
}

.cp-user-deposit-card-select ul li:not(.init) {
    display: none;
    border-top: none;
}

.cp-user-deposit-card-select li.init {
    cursor: pointer;
}

a#submit {
    z-index: 1;
}

.cp-user-past-address-list {
    min-height: 430px;
    overflow-y: auto;
}

/* Scrollbar */

.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
}

.scroll-wrapper>.scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
}

.scroll-wrapper>.scroll-content::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.scroll-element {
    display: none;
}

.scroll-element,
.scroll-element div {
    box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
    cursor: default;
}

.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;
}

.scroll-textarea>.scroll-content {
    overflow: hidden !important;
}

.scroll-textarea>.scroll-content>textarea {
    border: none !important;
    box-sizing: border-box;
    height: 100% !important;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    outline: none;
    padding: 2px;
    position: relative !important;
    top: 0;
    width: 100% !important;
}

.scroll-textarea>.scroll-content>textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
}




/*************** SCROLLBAR MAC OS X ***************/

.scrollbar-macosx>.scroll-element,
.scrollbar-macosx>.scroll-element div {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
}

.scrollbar-macosx>.scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}

.scrollbar-macosx>.scroll-element .scroll-element_track {
    display: none;
}

.scrollbar-macosx>.scroll-element .scroll-bar {
    background-color: #111A35;
    display: block;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    border-radius: 7px;

    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.scrollbar-macosx:hover>.scroll-element .scroll-bar,
.scrollbar-macosx>.scroll-element.scroll-draggable .scroll-bar {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    opacity: 0.7;
}


.scrollbar-macosx>.scroll-element.scroll-x {
    bottom: 0px;
    height: 0px;
    left: 0;
    min-width: 100%;
    overflow: visible;
    width: 100%;
}

.scrollbar-macosx>.scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 0px;
    top: 0;
    width: 0px;
}

/* scrollbar height/width & offset from container borders */
.scrollbar-macosx>.scroll-element.scroll-x .scroll-bar {
    height: 4px;
    min-width: 10px;
    top: -4px;
}

.scrollbar-macosx>.scroll-element.scroll-y .scroll-bar {
    left: -4px;
    min-height: 10px;
    width: 4px;
}

.scrollbar-macosx>.scroll-element.scroll-x .scroll-element_outer {
    left: 2px;
}

.scrollbar-macosx>.scroll-element.scroll-x .scroll-element_size {
    left: -4px;
}

.scrollbar-macosx>.scroll-element.scroll-y .scroll-element_outer {
    top: 2px;
}

.scrollbar-macosx>.scroll-element.scroll-y .scroll-element_size {
    top: -4px;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-macosx>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left: -11px;
}

.scrollbar-macosx>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top: -11px;
}

/*
----------------------------------------------------------
    (07) pockaet wallet css
----------------------------------------------------------
*/




/*
----------------------------------------------------------
    (08) withdraw css
----------------------------------------------------------
*/

.cp-user-withdraw-card .form-group {
    margin-bottom: 30px;
}

.cp-user-withdraw-card .form-control {
    background: transparent;
    border: 1px solid #2A3865;
    border-radius: 3px;
    box-shadow: none;
    color: #B4B8D7;
    height: 70px;
}

.cp-user-withdraw-card textarea.form-control {
    height: initial;
    padding: 15px;
}

.cp-user-withdraw-card label {
    color: #B4B8D7;
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.cp-user-withdraw-card .submit-btn {
    background: #2A3865;
    border-radius: 3px;
    height: 70px;
    color: #fff;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
}

.cp-user-withdraw-card .cp-user-coin-address {
    position: relative;
}

.cp-user-withdraw-card .cp-user-coin-address:after {
    position: absolute;
    content: url(../images/coin-address.svg);
    top: 55%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}


/*
----------------------------------------------------------
    (08) withdraw css
----------------------------------------------------------
*/


/*
----------------------------------------------------------
    (09) Activity css
----------------------------------------------------------
*/


.cp-user-activity-card .cp-user-card-header-area {
    border-bottom: 1px dashed #2B3C70;
}

.cp-user-activity-card .cp-user-card-header-area,
.cp-user-activity-card .cp-user-card-header-area-2 {
    padding-bottom: 30px;
}

.cp-user-activity-card .cp-user-card-header-area-2 h5 {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
}

.cp-user-activity-card .cp-user-card-header-area-2 .cp-user-chart-dropdown .btn {
    color: #B4B8D7;
    font-size: 16px;
    border: 1px dashed #111A35;
    border-radius: 5px;
    padding: 5px 15px;
}


/*
----------------------------------------------------------
    (09) Activity css
----------------------------------------------------------
*/



/*
----------------------------------------------------------
    (10) Profile css
----------------------------------------------------------
*/

.cp-user-profile-nav {
    background: #212E55;
    border-radius: 12px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    margin-bottom: 50px;
}

.cp-user-profile-nav .cp-user-img {
    width: 100%;
    display: block;
}

.cp-user-profile-nav .nav-link {
    text-align: center;
    color: #B4B8D7;
    font-size: 18px;
    width: 260px;
    text-transform: capitalize;
    padding: 20px 0 15px;
}

.cp-user-profile-nav .nav-link.active {
    box-shadow: 0 10px 20px rgba(17, 26, 53, 0.4);
}

.cp-user-profile-nav .nav-link.active,
.cp-user-profile-nav .nav-item:hover .nav-link {
    color: #F7941E;
}

.cp-user-profile-nav .nav-link.active .cp-user-img .img-active,
.cp-user-profile-nav .nav-item:hover .nav-link .cp-user-img .img-active,
.cp-user-profile-nav .cp-user-img .img-normal {
    display: block;
}

.cp-user-profile-nav .nav-link.active .cp-user-img .img-normal,
.cp-user-profile-nav .cp-user-img .img-active,
.cp-user-profile-nav .nav-item:hover .nav-link .cp-user-img .img-normal {
    display: none;
}

.cp-user-profile-nav .cp-user-img {
    margin-bottom: 10px;
}

.cp-user-profile-nav .cp-user-img img {
    display: table;
    margin: auto;
}

.cp-user-profile-tab-content .cp-user-custom-card {
    border-radius: 12px;
    padding: 30px;
}

.cp-user-profile-header {
    margin-bottom: 30px;
}

.cp-user-profile-header h5 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

.cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li {
    border-bottom: 1px solid #253562;
    padding: 30px 0;
    color: #B4B8D7;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li span.pending {
    color: #F7941E;
}

.cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li:first-child {
    border-top: 1px solid #253562;
}

.cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li span {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
}

.cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li span:last-child {
    text-align: right;
}

.cp-user-profile-tab-content .cp-user-custom-card .cp-user-profile-info ul li span.cp-user-dot {
    text-align: center;
}

.user-profile-img {
    height: 198px;
    width: 198px;
    border-radius: 50%;
    display: block;
    margin: auto;
    background: #182346;
    border: 4px solid #303D65;
    position: relative;
    overflow: hidden;
    box-shadow: 0 17px 35px rgba(2, 14, 52, 0.8);
}

.user-profile-img img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.user-cp-user-profile-info {
    text-align: center;
    margin-top: 30px;
}

.user-cp-user-profile-info h4 {
    color: #B4B8D7;
    font-weight: 700;
    font-size: 20px;
}

.user-cp-user-profile-info p {
    color: #B4B8D7;
    font-size: 16px;
    margin-top: 5px;
}

.user-cp-user-profile-info #upload-user-img {
    display: none;
}

.user-cp-user-profile-info .upload-user-img {
    max-width: 180px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 3px;
    background: #2A3865;
    box-shadow: 0 10px 20px rgba(24, 35, 70, 0.5);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.cp-user-profile-info .form-group {
    margin-bottom: 30px;
}

.cp-user-profile-info .form-control {
    background: transparent;
    border: 1px solid #2B3C70;
    border-radius: 3px;
    height: 70px;
    box-shadow: none;
}

.cp-user-profile-info label,
.cp-user-profile-info .form-control {
    color: #B4B8D7;
}

.cp-user-profile-info .profile-edit-btn {
    max-width: 530px;
    width: 100%;
    height: 70px;
    border-radius: 3px;
    background: #2A3865;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
}


/* phone verify */
.input-group-prepend .dropdown-toggle:after {
    display: none;
}

.input-group-prepend-phone-verify .btn {
    height: 70px;
    background: #2A3865;
    width: 150px;
    color: #B4B8D7;
    position: relative;
    font-size: 16px;
    text-align: left;
    padding: 0 25px;
}

.input-group-prepend-phone-verify .btn:before {
    position: absolute;
    content: url(../images/angle-down.svg);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 25px;
}

.verify-input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.verify-input {
    width: 245px;
}

/* id verify */
.modal {
    z-index: 99999999 !important;
}

.cp-user-idverifycard {
    min-height: 560px;
}

.cp-user-idverifymodal .modal-content {
    border-radius: 12px !important;
    position: relative;
}

.cp-user-idverifymodal .modal-body {
    padding: 50px;
}

.cp-user-profile-info-id-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 50px;
    flex-wrap: wrap;
}

.id-card-type {
    text-align: center;
    width: 33.33%;
    padding: 0 15px;
    position: relative;
}
.id-card-type .tagname{
    position: absolute;
    transform: rotate(90deg);
    font-size: 10px;
    left: 15px;
    top: 40px;
    color: #fff;
    text-transform: uppercase;
    z-index: 1;
}

.id-card-type .tagname::after{
    position: absolute;
    content: url(../images/tag-bg.png);
    left: -40px;
    top: -2px;
    height: 100%;
    width: 100%;
    transform: rotate(-90deg);
    z-index: -1;
}
.id-card-type h5 {
    color: #B4B8D7;
    font-size: 18px;
    font-weight: 700;
}

.id-card-type .id-card {
    background: #182346;
    border-radius: 12px;
    min-height: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 15px 30px rgba(17, 26, 53, 0.3);
    margin-bottom: 30px;
    cursor: pointer;
}

.idcard .cp-user-title {
    font-size: 18px;
    font-weight: 700;
    color: #2A3865;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.idcard .card-inner {
    border-radius: 12px;
    width: 290px;
    height: 160px;
    border: 1px solid #E4E6F4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.iduploadbtn {
    max-width: 290px;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    position: relative;
    z-index: 1;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 700;
    color: #2A3865;
    text-align: center;
    line-height: 50px;
    text-transform: capitalize;
    cursor: pointer;
}

.iduploadbtn:after {
    position: absolute;
    content: url(../images/cards/uploadimgbg.svg);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 3px;
}

.carduploadbtn {
    background: #2A3865;
    height: 60px;
    text-align: center;
    line-height: 60px;
    padding: 0;
    font-weight: 700;
    font-size: 18px;
    max-width: 480px;
    width: 100%;
    text-transform: capitalize;
    display: block;
    margin: 30px auto 0;
}

.carduploadbtn,
.carduploadbtn:hover {
    border-radius: 3px;
    color: #fff;
}

.cp-user-idverifymodal .modal-content .close {
    position: absolute;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    top: -15px;
    right: -15px;
    background: #E9EBF2;
    padding: 0;
}

.cp-user-idverifymodal .modal-content .close,
.cp-user-idverifymodal .modal-content .close:hover {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}


/*
----------------------------------------------------------
    (10) Profile css
----------------------------------------------------------
*/



/*
----------------------------------------------------------
    (11) Setting css
----------------------------------------------------------
*/

.cp-user-setting-card .cp-user-card-header-area {
    padding-top: 20px;
    border-bottom: 1px dashed #2B3C70;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.cp-user-auth-icon {
    height: 172px;
    width: 172px;
    border-radius: 10px;
    border: 1px solid #2B3C70;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cp-user-setting-card .cp-user-content {
    margin: 40px 0;
}

.cp-user-setting-card .cp-user-content h5 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
}

.cp-user-setting-card .cp-user-content p {
    font-size: 16px;
    color: #B4B8D7;
}

.cp-user-setting-card .cp-user-content .cp-user-setupbtn {
    background: #2A3865;
    color: #fff;
    border-radius: 3px;
    font-weight: 700;
    font-size: 20px;
    height: 70px;
    min-width: 455px;
    line-height: 70px;
    padding: 0;
    text-align: center;
    margin-top: 30px;
}

.cp-user-setting-card .cp-user-content .switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 40px;
    margin-top: 30px;
}

.cp-user-setting-card .cp-user-content .switch input {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    width: 0;
    height: 0;
}

.cp-user-setting-card .cp-user-content .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2A3865;
    -webkit-transition: .4s;
    transition: .4s;
}

.cp-user-setting-card .cp-user-content .slider:before {
    position: absolute;
    content: "";
    height: 34px;
    width: 34px;
    left: 4px;
    top: 50%;
    background: url(../images/switch-btn.svg) no-repeat center center / cover;
    -webkit-transition: .4s;
    transition: .4s;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cp-user-setting-card .cp-user-content input:checked+.slider {
    background: #182346;
}

.cp-user-setting-card .cp-user-content input:checked+.slider:before {
    background: url(../images/switch-btn-off.svg) no-repeat center center / cover;
}

.cp-user-setting-card .cp-user-content input:checked+.slider:before {
    -webkit-transform: translate(58px, -50%);
    transform: translate(58px, -50%);
}

/* Rounded sliders */
.cp-user-setting-card .cp-user-content .slider.round:before,
.cp-user-setting-card .cp-user-content .slider.round {
    border-radius: 50px;
}


.cp-user-preferance-setting .form-control {
    background: #182346;
    color: #B4B8D7;
    border: 1px solid #182346;
    border-radius: 3px;
    height: 70px;
    font-size: 18px;
    box-shadow: none;
}

.cp-user-preferance-setting {
    position: relative;
}

.cp-user-preferance-setting:after {
    position: absolute;
    content: url(../images/cart-down.svg);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.cp-user-setting-card .cp-user-content .form-group {
    margin-bottom: 30px;
}

.cp-user-setting-card .cp-user-content label {
    color: #fff;
    font-size: 18px;
    margin-bottom: 15px;
}

.cp-user-setting-card-inner-preference {
    min-height: 660px;
}


/*
----------------------------------------------------------
    (11) Setting css
----------------------------------------------------------
*/


/*
----------------------------------------------------------
    (12) Referral css
----------------------------------------------------------
*/
.cp-user-card-header-bb {
    border-bottom: 1px dashed #2B3C70;
    padding-bottom: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.cp-user-referral-content .form-control {
    background: transparent;
    border: 1px solid #2B3C70;
    border-radius: 3px;
    text-align: center;
    color: #B4B8D7;
    font-size: 16px;
    box-shadow: none;
}

.cp-user-referral-content label {
    font-size: 16px;
    color: #B4B8D7;
    margin-bottom: 15px;
}

.cp-user-referral-content .copy-url-btn {
    background: #2B3C70;
    color: #B4B8D7;
    font-size: 18px;
    font-weight: 700;
    width: 170px;
    border-radius: 3px 0 0 3px;
}

.cp-user-card-header-area .cp-user-card-header-bb .cp-user-content-bottom {
    margin-top: 30px;
}

.cp-user-referral-content .cp-user-content-bottom .or {
    font-size: 20px;
    font-weight: 700;
    color: #B4B8D7;
}

.cp-user-share-title {
    font-size: 18px;
    color: #fff;
    margin: 30px 0;
}

.cp-user-share-buttons ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cp-user-share-buttons ul li:not(:last-child) {
    margin-right: 30px;
}

.cp-user-share-buttons ul li a {
    width: 150px;
    height: 60px;
    line-height: 60px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 700;
}

.cp-user-share-buttons ul li a i {
    margin-right: 10px;
}

.cp-user-share-buttons ul li a.fb {
    background: #3B5998;
}

.cp-user-share-buttons ul li a.twit {
    background: #55ACEE;
}

.cp-user-share-buttons {
    margin-bottom: 30px;
}

.cp-user-myreferral table.dataTable tbody tr {
    background: transparent !important;
}

.cp-user-myreferral table.dataTable thead tr th {
    font-weight: 700;
    font-size: 18px;
}

.cp-user-myreferral table.dataTable tbody tr td {
    font-size: 16px;
}

.cp-user-myreferral table.dataTable thead tr th,
.cp-user-myreferral table.dataTable tbody tr td {
    color: #B4B8D7;
    height: 70px;
    vertical-align: middle;
}

.cp-user-myreferral table.dataTable thead th,
.cp-user-myreferral table.dataTable thead td {
    border-bottom: none;
}

.cp-user-myreferral table.dataTable thead th {
    border-top: 1px solid #182346;
}

.cp-user-myreferral table.dataTable thead th,
.cp-user-myreferral table.dataTable tbody td {
    border-bottom: 1px solid #182346;
}


/*
----------------------------------------------------------
    (12) Referral css
----------------------------------------------------------
*/