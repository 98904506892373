ol, ul, dl {
    margin-bottom: inherit;
}
.user-management .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
    color: #B4B8D7;
}
.user-management .nav-tabs {
    border-bottom: 1px solid #182346 !important;
}
.user-management .nav-tabs {
    border-bottom: none;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent !important;
    color: #B4B8D7;
    cursor: pointer;
}
.pidverify .card-wrapper {
    width: inherit;
}
.modal-header {
    background: #ffffff;
    color: #171717;
    padding: 0 !important;
    border-bottom: none !important;
}
.modal-content img{
    margin-top: -70px;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    width: 100% !important;
    margin-top: 15px;
}
.modal-title h4{
    color: #111A35;
    font-weight: 700;
    font-size: 24px;

}
.modal-header .close {
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #f0f0f0;
    padding: 0 !important;
    border-radius: 50px;
    top: -75px;
    position: relative;
    opacity: 1 !important;
}
.pidverify-page button.btn {
    float: right;
}
.pidverify-page .btn:hover {
    color: #fff;
    text-decoration: none;
}
.user-management .nav-tabs {
    border-bottom: none !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: transparent !important;;
}
.cp-user-top-bar .dropdown-menu-right {
    display: inline !important;
}
.cp-user-chart-dropdown .dropdown-menu {
    display: inline !important;
}
.Toastify__toast {
    z-index: 999999999 !important;
}
@media (min-width: 768px) {
    .abc_modal .modal-dialog {
        max-width: 55%;
        margin: 1.75rem auto;
    }
}
.cp-user-myreferral .table {
    color: #dee2e6;
}
.cp-user-coin-info-area h5, .btn {
    color: #fff !important;
}
.cp-user-coin-info-area .cp-user-setupbtn {
    background: #2A3865;
    color: #fff;
    border-radius: 3px;
    font-weight: 700;
    font-size: 20px;
    height: 70px;
    min-width: 455px;
    line-height: 70px;
    padding: 0;
    text-align: center;
    margin-top: 30px;
}
a.btn.link-button.cp-user-move-coin {
    padding-top: 10px;
}

.cp-user-generate-address {
    font-size: 20px;
    text-transform: capitalize;
    color: #fff;
    border-bottom: 1px solid #fff;
    display: inline-block;
    margin-bottom: 30px;
    font-weight: 700;
}
.cp-user-generate-address:hover{
    color: #B4B8D7;
}
.cp-user-show-past-address{
    margin-top: 50px;
}
.cp-user-activity-tab .nav-link{
    color: #B4B8D7;
}
.cp-user-activity-tab .nav-link.active{
    color: #F7941E;
}
.cp-user-my-wallet-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}
.cp-user-my-wallet-header h4{
    color: #fff
}
.cp-user-my-wallet-header .btn{
    line-height: 50px;
    padding: 0;
    background: #2B3C70 !important;
    color: #B4B8D7;
    width: 150px !important;
}

@media screen and (max-width: 1024px) {
    .cp-user-deposit-info .cp-user-withdraw-card .form-control {
        height: 55px;
    }
}

@media screen and (max-width: 375px) {
    .cp-user-my-wallet-header {
        display: block;
        text-align: center;
    }
    .cp-user-my-wallet-header h4{
        border-bottom: 1px dashed #fff;
        padding-bottom: 15px;
    }
    .cp-user-my-wallet-header .btn{
        margin-top: 15px;
    }
    .cp-user-referral-content .copy-url-btn {
        font-size: 14px;
        width: 80px !important;
    }
}

.cp-user-qr img {
    width: 200px !important;
}
.nimmu-user-sibmit-button:hover {
    background: #0062cc !important;
}